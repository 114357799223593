angular.module('app', [
        // Angular modules
        //'ngRoute',
        // Custom modules

        // 3rd Party Modules
        'ui.bootstrap',
        'angular-password',
        'ngSanitize'
]);

angular.module('app').config(function ($locationProvider) {
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
        rewriteLinks: false
    });
});
angular.module('app').controller('FormController', ['$scope', '$http', function ($scope, $http) {

    $scope.errorMessage = '';
    $scope.successMessage = '';

    $scope.sendBecomeASupplierForm = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';

        var url = '/sitecore/api/ssc/Maroon.Web/FormsApi/v1/BecomeASupplier';

        $http.post(url,
            {
                FirstName: $scope.userFirstName,
                LastName: $scope.userLastName,
                Title: $scope.userTitle,
                Division: $scope.userDivision,
                Email: $scope.userEmail,
                Phone: $scope.userTelephone,
                CompanyName: $scope.userCompanyName,
                Industry: $scope.userIndustry,
                Address1: $scope.userAddressLine1,
                Address2: $scope.userAddressLine2,
                Zip: $scope.userZipcode,
                City: $scope.userCity,
                State: $scope.userState,
                TellUsMore: $scope.userAdditionalInformation,
                SubscribeToMaroonGroupNewsletter: $scope.userSubscribeToNewsletter
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Become A Supplier form successfuly submitted';
                } else {
                    var error = response.data.error;
                   
                        $scope.errorMessage = 'An error has occured! ' + error;
                }
            }).catch(function (e) {
                $scope.errorMessage = 'Error - Contact your admin! ' + e;
            });
    };
    $scope.sendContactForm = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';

        var url = '/sitecore/api/ssc/Maroon.Web/FormsApi/v1/ContactForm';

        $http.post(url,
            {
                FirstName: $scope.userFirstName,
                LastName: $scope.userLastName,
                Title: $scope.userTitle,
                Division: $scope.userDivision,
                Email: $scope.userEmail,
                Phone: $scope.userTelephone,
                CompanyName: $scope.userCompanyName,
                Industry: $scope.userIndustry,
                Address1: $scope.userAddressLine1,
                Address2: $scope.userAddressLine2,
                Zip: $scope.userZipcode,
                City: $scope.userCity,
                State: $scope.userState,
                TellUsMore: $scope.userAdditionalInformation,
                SubscribeToMaroonGroupNewsletter: $scope.userSubscribeToNewsletter
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Contact form successfuly submitted';
                } else {
                    var error = response.data.error;
                   
                        $scope.errorMessage = 'An error has occured! ' + error;
                }
            }).catch(function (e) {
                $scope.errorMessage = 'Error - Contact your admin! ' + e;
            });
    };
    $scope.userProfile = function () {
        var data;
        var url = '/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/GetCurrentUser';
        $http.get(url)
            .then(function (response) {
                if (response.data.isAuthenticated) {
                    $scope.userFirstName = response.data.user.firstName;
                    $scope.userLastName = response.data.user.lastName;
                    $scope.userTitle = response.data.user.title;
                    $scope.userDivision = response.data.user.division;
                    $scope.userEmail = response.data.user.email;
                    $scope.userTelephone = response.data.user.telephone;
                    $scope.userCompanyName = response.data.user.companyName;
                    $scope.userIndustry = response.data.user.industry;
                    $scope.userAddressLine1 = response.data.user.addressLine1;
                    $scope.userAddressLine2 = response.data.user.addressLine2;
                    $scope.userZipcode = response.data.user.zipcode;
                    $scope.userCity = response.data.user.city;
                    $scope.userState = response.data.user.state;
                    $scope.userSubscribeToNewsletter = response.data.user.subscribeToNewsletter;
                }
        });
        return false;
    };
}]);
angular.module('app').controller('HeaderController', ['$scope', '$uibModal', function ($scope, $uibModal) {

    $scope.openSearch = function () {
        $scope.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'product-search.html',
            controller: 'DefaultModalController',
            size: 'xl',
            windowClass: 'header-search'
        });
    };

}]);

angular.module('app').controller('DefaultModalController', ['$uibModalInstance', '$scope', '$uibModal', function ($uibModalInstance, $scope, $uibModal) {

    //**** Modal Actions ****// 
    $scope.ok = function () {
        $uibModalInstance.close();
    };
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}]);
angular.module('app').controller('ModalController', ['$scope', '$uibModal', '$http', '$location', function ($scope, $uibModal, $http, $location) {
    
    $scope.animationsEnabled = true;

    $scope.open = function (size, modalID) {

        if (angular.isNumber(modalID)) {
            modalID = modalID + '.html';
        }
        $scope.modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: modalID,
            controller: 'ModalInstanceController',
            size: size,
            resolve: {
                data: function () {
                    return null;
                }
            }
        });
        var platformNavigation = document.getElementById('platformNavigation');
        if (platformNavigation) {
            $('.modal-content').addClass('sitecoreHeaderMargin');
        }
    }
    $scope.checkResetForm = function () {
        var reset = $location.search().reset;
        if (reset != undefined) {
            $scope.modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'resetPasswordByEmailForm.html',
                controller: 'ModalInstanceController',
                size: 'lg',
                resolve: {
                    data: function () {
                        return null;
                    }
                }
            });
        }
    };
}]);
angular.module('app').controller('ModalInstanceController', ['$uibModalInstance', '$scope', '$uibModal', '$http', '$location', 'data', function ($uibModalInstance, $scope, $uibModal, $http, $location, data) {

    $scope.errorMessage = '';
    $scope.successMessage = '';

    if (data)
    {
        if (data.contentPress) {
            $scope.contentPress = data.contentPress;
        } else {
            if (data.product) {
                $scope.product = data.product;
            }
            if (data.data) {
                if (data.data.user) {
                    $scope.userFirstName = data.data.user.firstName;
                    $scope.userLastName = data.data.user.lastName;
                    $scope.userEmail = data.data.user.email;
                    $scope.userTitle = data.data.user.title;
                    $scope.userDivision = data.data.user.division;
                    $scope.userTelephone = data.data.user.telephone;
                    $scope.userCompanyName = data.data.user.companyName;
                    $scope.userIndustry = data.data.user.industry;
                    $scope.userAddressLine1 = data.data.user.addressLine1;
                    $scope.userAddressLine2 = data.data.user.addressLine2;
                    $scope.userZipcode = data.data.user.zipcode;
                    $scope.userCity = data.data.user.city;
                    $scope.userState = data.data.user.state;
                    $scope.userSubscribeToNewsletter = data.data.user.subscribeToNewsletter;
                }
            }
        }
    }
    $scope.login = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $http.post('/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/Login',
            {
                Username: $scope.Username,
                Password: $scope.Password
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $uibModalInstance.close();
                    var reset = $location.search().reset;
                    if (reset != undefined) {
                        $location.url($location.path());
                    }
                    window.location.reload();
                } else {
                    $scope.errorMessage = response.data.message;
                }
            }).catch(function(e) {
                $scope.errorMessage = 'Systrem error: ' + e;
            });
    };
    $scope.forgotPassword = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $http.post('/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/ForgotPassword',
            {
                Email: $scope.ForgotEmail
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Password sent! Check your email.';
                } else {
                    var message = response.data.message;
                    if (message != '')
                        $scope.errorMessage = message;
                    else
                        $scope.errorMessage = 'Send email failed!';
                }
            }).catch(function(e) {
                $scope.errorMessage = 'Systrem error: ' + e;
            });
    };
    $scope.resetPassword = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $http.post('/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/ResetPassword',
            {
                oldPassword: $scope.oldPassword,
                newPassword: $scope.Password
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Password reset!';
                } else {
                    $scope.errorMessage = 'Reset password reset failed! Try again.';
                }
            }).catch(function(e) {
                $scope.errorMessage = 'Systrem error: ' + e;
            });
    };
    $scope.registerUserRequest = function (user_authenicated) {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        user_authenicated = user_authenicated == 'True' ? true : false;

        var url = '/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/CreateUser';
        if(user_authenicated)
            url = '/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/UpdateUser';

        $http.post(url,
            {
                Password: $scope.userPassword,
                User: {FirstName: $scope.userFirstName,
                    LastName: $scope.userLastName,
                    Title: $scope.userTitle,
                    Division: $scope.userDivision,
                    Email: $scope.userEmail,
                    Telephone: $scope.userTelephone,
                    CompanyName: $scope.userCompanyName,
                    Industry: $scope.userIndustry,
                    AddressLine1: $scope.userAddressLine1,
                    AddressLine2: $scope.userAddressLine2,
                    Zipcode: $scope.userZipcode,
                    City: $scope.userCity,
                    State: $scope.userState,
                    SubscribeToNewsletter: $scope.userSubscribeToNewsletter}
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = user_authenicated ? 'User profile updated!' : 'User Registration Request sent!';
                    $uibModalInstance.close();
                } else {
                    var message = response.data.message;
                    if (message != '')
                        $scope.errorMessage = message;
                    else
                        $scope.errorMessage = 'User profile operation failed!';
                }
            }).catch(function(e) {
                $scope.errorMessage = 'Error - Contact your admin! ' + e;
            });
    };
    $scope.resetPasswordOnly = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $http.post('/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/ResetPasswordByUsername',
            {
                userName: $scope.reset_userName,
                newPassword: $scope.Password
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Password reset!';
                } else {
                    $scope.errorMessage = 'Reset password reset failed! Try again.';
                }
            }).catch(function(e) {
                $scope.errorMessage = 'Systrem error: ' + e;
                console.log(e)
            });
    };
    $scope.userProfile = function () {

        var data;
        var url = '/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/GetCurrentUser';

        $http.get(url)
          .then(function (response) {
            data = response;
            $uibModalInstance.close();
            $scope.modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'userRegistration.html',
                controller: 'ModalInstanceController',
                size: 'lg',
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
      });
        return false;
    };
    $scope.logout = function () {
        $http.post('/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/Logout',
            { })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    window.location.reload();
                }
            }).catch(function(e) {
                //var error = "Need to error message: Contact your admin! " + e;
            });
    };
    $scope.sendRequesteASampleForm = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';

        var url = '/sitecore/api/ssc/Maroon.Web/FormsApi/v1/SampleRequestForm';

        $http.post(url,
            {
                ProductName: $scope.product.name,
                ProductId: $scope.product.productId,
                FirstName: $scope.userFirstName,
                LastName: $scope.userLastName,
                Title: $scope.userTitle,
                Division: $scope.userDivision,
                Email: $scope.userEmail,
                Phone: $scope.userTelephone,
                CompanyName: $scope.userCompanyName,
                Industry: $scope.userIndustry,
                Address1: $scope.userAddressLine1,
                Address2: $scope.userAddressLine2,
                Zip: $scope.userZipcode,
                City: $scope.userCity,
                State: $scope.userState,
                TellUsMore: $scope.userAdditionalInformation,
                SubscribeToMaroonGroupNewsletter: $scope.userSubscribeToNewsletter
            })
            .then(function (response) {
                var result = response.data.success;
                if (result) {
                    $scope.successMessage = 'Request a Sample successfuly submitted';
                } else {
                    var error = response.data.error;
                    $scope.errorMessage = 'An error has occured! ' + error;
                }
            }).catch(function (e) {
                $scope.errorMessage = 'Error - Contact your admin! ' + e;
            });
    };



    //**** Modal Actions ****// 
    $scope.ok = function () {
        $uibModalInstance.close();
    };
    $scope.cancel = function () {
        $uibModalInstance.close();
        return false;
    };
    $scope.forgot = function () {
        $uibModalInstance.close();
        $scope.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'forgotPassword.html',
            controller: 'ModalInstanceController',
            size: 'lg',
            resolve: {
                data: function () {
                    return null;
                }
            }
        });
        return false;
    };
    $scope.reset = function () {
        $uibModalInstance.close();
        $scope.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'resetPasswordForm.html',
            controller: 'ModalInstanceController',
            size: 'lg',
            resolve: {
                data: function () {
                    return null;
                }
            }
        });
        return false;
    };
    $scope.register = function () {
        $uibModalInstance.close();
        $scope.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'userRegistration.html',
            controller: 'ModalInstanceController',
            size: 'lg',
            resolve: {
                data: function () {
                    return null;
                }
            }
        });
        return false;
    };

    $scope.animationsEnabled = true;

    $scope.open = function (size, modalID) {
        $uibModalInstance.close();
        if (angular.isNumber(modalID)) {
            modalID = modalID + '.html';
        }
        $scope.modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: modalID,
            controller: 'ModalInstanceController',
            size: size,
            resolve: {
                data: function () {
                    return null;
                }
            }
        });
    }
}]);
angular.module('app').controller('RequestSampleController', ['$scope', '$http', '$timeout', '$filter', '$location', '$uibModal', function ($scope, $http, $timeout, $filter, $location, $uibModal) {

    $scope.requestSample = function (product, user_authenicated) {

        user_authenicated = user_authenicated != '' ? true : false;
        if (!user_authenicated) {
            $scope.modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'requestSample.html',
                controller: 'ModalInstanceController',
                size: 'xl',
                resolve: {
                    data: function () {
                        null
                    }
                }
            });
        }
        else
        {
            var data;
            var url = '/sitecore/api/ssc/Maroon.Web/MaroonUsersApi/v1/GetCurrentUser';
            $http.get(url)
              .then(function (response) {
                data = response;
                $scope.modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'requestSample.html',
                    controller: 'ModalInstanceController',
                    size: 'xl',
                    resolve: {
                        data: function () {
                              return {
                                  data: data.data,
                                  product: product
                              };
                        }
                    }
                });
            });
        }
    };

}]);
angular.module('app').controller('PlatformPressController', ['$scope', '$http', '$timeout', '$filter', '$location', '$uibModal', function ($scope, $http, $timeout, $filter, $location, $uibModal) {

    $http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/GetPress', {
        'Offset': 0,
        'PageSize': 3
    })
    .then(function (response) {
        $scope.pressCards = response.data;
    });

    $http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/GetPress', {
        'Offset': 3,
        'PageSize': 50
    })
    .then(function (response) {
        $scope.additionalPressCards = response.data;
    });

    $scope.modalPress = function (contentPress) {
        if (contentPress != '') {
            $scope.modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'modalPress.html',
                controller: 'ModalInstanceController',
                size: 'lg',
                resolve: {
                    data: function () {
                        return {
                            contentPress: contentPress
                        };
                    }
                }
            });
        }
    };
            
}]);
angular.module('app').controller('ProductListingController', ['$scope', '$http', '$timeout', '$filter', '$location', '$uibModal', function ($scope, $http, $timeout, $filter, $location, $uibModal) {

  $scope.queryString = $location.search();
  $scope.query = $scope.queryString.query;

  $scope.isLoading = true;

  $scope.searchResults = {
    products: []
  };

  $scope.searchTags = {
    classes: [],
    industries: [],
    suppliers: []
  };

  $scope.showClasses = true;
  $scope.showSuppliers = true;
  $scope.showIndustries = true;

  $scope.selectedClasses = [];
  $scope.selectedSubClasses = [];
  $scope.selectedSuppliers = [];
  $scope.selectedIndustries = [];


  $scope.operatingCompanies = [];
  $scope.operatingCompaniesQueryString = '';

  /*$http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/searchTags', {})
      .then(function (response) {
          $scope.searchTags.classes = response.data.classes;
          $scope.searchTags.industries = response.data.industries;
          $scope.searchTags.suppliers = response.data.suppliers;
      });*/

  $scope.productList = [];

  $scope.search = function () {
    $scope.isLoading = true;
    $http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/search',
        {
          query: $scope.query
        })
      .then(function (response) {
        
        $scope.searchResults = response.data;
        $scope.searchTags.classes = response.data.classes;
        $scope.searchTags.industries = response.data.industries;
        $scope.searchTags.suppliers = response.data.suppliers;
        addQueryString();
        $scope.productList = filterProducts();
        $scope.isLoading = false;
        //setSearchTagCounts();

        $scope.operatingCompanies = response.data.operatingCompanies;
        $scope.operatingCompaniesQueryString = response.data.queryString;
      });
  };

  if ($scope.query) {
    $scope.search();
  } else {
    $scope.isLoading = true;
    $http.get('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/search')
      .then(function (response) {
        $scope.searchResults = response.data;
        $scope.searchTags.classes = response.data.classes;
        $scope.searchTags.industries = response.data.industries;
        $scope.searchTags.suppliers = response.data.suppliers;
        // Add more results for testing larger data sets
        //for (var i = 0; i < 5; i++) {
        //    response.data.products = response.data.products.concat(angular.copy(response.data.products));
        //}
        addQueryString();

        $scope.productList = filterProducts();
        $scope.isLoading = false;
        //setSearchTagCounts();
      });
  }

  $scope.selectSubClass = function (subclass) {
    if (!_.find($scope.selectedSubClasses, { 'scName': subclass.scName })) {
      $scope.selectedSubClasses.push(subclass);
    }
    $scope.productList = filterProducts();
  };

  $scope.unSelectSubClass = function (subclass) {
    _.remove($scope.selectedSubClasses, { 'scName': subclass.scName });
    $scope.productList = filterProducts();
  };

  $scope.getFilterCount = function (type, extraTags) {
    var extra = {};
    extra[type] = extraTags;
    return filterProducts(extra).length;
  }

  $scope.$watchGroup(['selectedClasses', 'selectedIndustries', 'selectedSuppliers'], function (newVal, oldVal) {
    $scope.productList = filterProducts();
    //setSearchTagCounts();
  }, true);

  $scope.removeFilter = function (collection, item) {
    item.checked = false;
    collection = $filter('filter')(collection, { checked: true });
  };

  var setSearchTagCounts = function () {

    _.forEach($scope.searchTags.classes, function (value) {
      _.find($scope.searchTags.classes, { 'scName': value.scName }).count = $scope.getFilterCount('class', value);
    });

    _.forEach($scope.searchTags.industries, function (value) {
      _.find($scope.searchTags.industries, { 'scName': value.scName }).count = $scope.getFilterCount('industry', value);
    });

    _.forEach($scope.searchTags.suppliers, function (value) {
      _.find($scope.searchTags.suppliers, { 'scName': value.scName }).count = $scope.getFilterCount('supplier', value);
    });

  };

  var addQueryString = function () {
    var products = null;
    // Filter by query string
    if ($scope.queryString.class) {
      var queryClass = _.find($scope.searchTags.classes, { 'scName': $scope.queryString.class });
      if (queryClass) {
        queryClass.checked = true;
        products = filterProducts({
          class: queryClass
        });
      }
    }
    if ($scope.queryString.industry) {
      var queryIndustry = _.find($scope.searchTags.industries, { 'scName': $scope.queryString.industry });
      
      if (queryIndustry) {
        queryIndustry.checked = true;
        products = filterProducts({
          industry: queryIndustry
        });
      }
    }
    if ($scope.queryString.supplier) {
      var querySupplier = _.find($scope.searchTags.suppliers, { 'scName': $scope.queryString.supplier });
      if (querySupplier) {
        querySupplier.checked = true;
        products = filterProducts({
          supplier: querySupplier
        });
      }
    }
    if (products != null) {
      $scope.searchTags.classes = _.filter($scope.searchTags.classes, function (c) {
        return (_.some(products, function (item) {
          return item.class != null && item.class.scName == c.scName;
        }));
      });
      $scope.searchTags.industries = _.filter($scope.searchTags.industries, function (i) {
        return (_.some(products, function (item) {
          return _.find(item.industries, function (i_industry) {
            return (i_industry.scName == i.scName);
          });
        }));
      });
      $scope.searchTags.suppliers = _.filter($scope.searchTags.suppliers, function (s) {
        return (_.some(products, function (item) {
          return item.supplier != null && item.supplier.scName == s.scName;
        }));
      });
    }
  };

  var filterProducts = function (extraTags) {

    // Add extra tags to be included in filter (for counting)
    if (extraTags === undefined) {
      extraTags = {};
    }

    var productList = $scope.searchResults.products;

    var selectedClasses = angular.copy($scope.selectedClasses),
      selectedSubClasses = angular.copy($scope.selectedSubClasses),
      selectedIndustries = angular.copy($scope.selectedIndustries),
      selectedSuppliers = angular.copy($scope.selectedSuppliers);

    if (extraTags.class) {
      if (!_.find(selectedClasses, { 'scName': extraTags.class.scName })) {
        selectedClasses.push(extraTags.class);
      }
    }
    if (extraTags.industry) {
      if (!_.find(selectedIndustries, { 'scName': extraTags.industry.scName })) {
        selectedIndustries.push(extraTags.industry);
      }
    }
    if (extraTags.supplier) {
      if (!_.find(selectedSuppliers, { 'scName': extraTags.supplier.scName })) {
        selectedSuppliers.push(extraTags.supplier);
      }
    }

    // Filter products based on criteria

    if (selectedClasses.length) {
      productList = _.filter(productList, function (item) {
        if (!item.class) {
          return;
        }
        return _.find(selectedClasses, { 'scName': item.class.scName });
      });
    }

    if (selectedSubClasses.length) {
      productList = _.filter(productList, function (item) {
        return _.find(selectedSubClasses, function (subclass) {
          return _.find(item.subclasses, { 'scName': subclass.scName });
        });
      });
    }

    if (selectedIndustries.length) {
      productList = _.filter(productList, function (item) {
        return _.find(selectedIndustries, function (industry) {
          return _.find(item.industries, { 'scName': industry.scName });
        });
      });
    }

    if (selectedSuppliers.length) {
      productList = _.filter(productList, function (item) {
        if (!item.supplier) {
          return;
        }
        return _.find(selectedSuppliers, { 'scName': item.supplier.scName });
      });
    }

    return productList;

  };

}]);

angular.module('app').controller('PlatformProductSearchController', ['$scope', '$window', function ($scope, $window) {

    $scope.showIndustry = false;
    $scope.showClass = false;
    $scope.showSupplier = false;

    $scope.submit = function () {
        $window.location.href = '/search/?query=' + $scope.query; 
    };
}]);

angular.module('app').controller('ProductSearchController', ['$scope', '$window', function ($scope, $window) {

    $scope.showIndustry = false;
    $scope.showClass = false;
    $scope.showSupplier = false;

    $scope.submit = function () {
      $window.location.href = '/products/search/?query=' + $scope.query; 
    };
}]);

angular.module('app').controller('VerticalListingController', ['$scope', '$http', '$timeout', '$filter', '$location', '$uibModal', '$window', function ($scope, $http, $timeout, $filter, $location, $uibModal, $window) {

    $scope.queryString = $location.search();
    $scope.query = $scope.queryString.query;
    $scope.staticsearchterm = $scope.queryString.query;
    $scope.querystringToPass = '';

    if (jQuery.isEmptyObject($scope.queryString)) {
      $scope.queryString = '';
    }
  
    var querystringkey = ''
    var querystringvalue = '';
    angular.forEach($scope.queryString, function (val, key) {
      if (key.toLowerCase() == 'industry' || key.toLowerCase() == 'class' || key.toLowerCase() == 'supplier') {
        querystringkey = key;
        querystringvalue = val;
        $scope.querystringToPass = querystringkey + '=' + querystringvalue;
      }
    });
    
    //scott maybe set this to false initially
    $scope.isLoading = false;
    if (Object.keys($scope.queryString).length > 0) {
      $scope.isLoading = true;
    }

    $scope.searchResults = {
        products: []
    };

    $scope.searchTags = {
        classes: [],
        industries: [],
        suppliers: []
    };

    $scope.showClasses = true;
    $scope.showSuppliers = true;
    $scope.showIndustries = true;

    $scope.selectedClasses = [];
    $scope.selectedSubClasses = [];
    $scope.selectedSuppliers = [];
    $scope.selectedIndustries = [];


    $scope.operatingCompanies = [];
    $scope.operatingCompaniesQueryString = '';
    $scope.verticalList = [];

    $scope.removeFilterRedirect = function () {

      if (typeof $scope.query !== 'undefined') {
        $window.open($location.$$path + '?query=' + $scope.query, '_self');
      }
      else {
        $window.open($location.$$path, '_self');
      }  
    };

    $scope.removeQueryRedirect = function () {
      var stringkey = ''
      var stringvalue = '';
      angular.forEach($scope.queryString, function (val, key) {
        if (key.toLowerCase() == 'industry' || key.toLowerCase() == 'class' || key.toLowerCase() == 'supplier') {
          stringkey = key;
          stringvalue = val;
        }  
      });
      
      if (typeof $scope.queryString !== 'undefined' && !jQuery.isEmptyObject($scope.queryString) && stringkey != '' && stringvalue != '') {
        $window.open($location.$$path + '?' + stringkey + '=' + stringvalue, '_self');
      }
      else {
        $window.open($location.$$path, '_self');
      }
      
  };

    
     
  $scope.search = function () {
      var dataObject = {};
      if ($scope.queryString.industry != '' && typeof $scope.queryString.industry !== 'undefined') {
        dataObject['industries'] = [$scope.queryString.industry];
      }
      if ($scope.queryString.class != '' && typeof $scope.queryString.class !== 'undefined') {
        dataObject['classes'] = [$scope.queryString.class];
      }
      if ($scope.queryString.supplier != '' && typeof $scope.queryString.supplier !== 'undefined') {
        dataObject['suppliers'] = [$scope.queryString.supplier];
      }
      if ($scope.query != '' && typeof $scope.query !== 'undefined') {
        dataObject['query'] = $scope.query;
      }
      
      if ($scope.query || Object.keys($scope.queryString).length > 0) {
        $scope.isLoading = true;
        $http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/searchVerticals',dataObject)
          .then(function (response) {
            
            $scope.searchResults = response.data;
            $scope.searchTags.classes = response.data.classes;
            $scope.searchTags.industries = response.data.industries; 
            $scope.searchTags.suppliers = response.data.suppliers;
            addQueryString();
            $scope.verticalList = filterProducts();
            $scope.isLoading = false;

            if (typeof $scope.query !== 'undefined') {
              $scope.staticsearchterm = $scope.query;
            } else {
              $scope.staticsearchterm = '';
            }

            //setSearchTagCounts();

            $scope.operatingCompanies = response.data.operatingCompanies;
            $('.pill-area').show();
            $('.results-area').show();

            var tempstring = response.data.queryString;
            if (tempstring != '') {
              tempstring = tempstring;
            }
            else {
              tempstring = $scope.querystringToPass;
            }
            $scope.operatingCompaniesQueryString = tempstring;
          });
      }
    };


  if ($scope.query || Object.keys($scope.queryString).length > 0) {
      var dataObject = {};
      if ($scope.queryString.industry != '' && typeof $scope.queryString.industry !== 'undefined') {
        dataObject['industries'] = [$scope.queryString.industry];
      }
      if ($scope.queryString.class != '' && typeof $scope.queryString.class !== 'undefined') {
        dataObject['classes'] = [$scope.queryString.class];
      }
      if ($scope.queryString.supplier != '' && typeof $scope.queryString.supplier !== 'undefined') {
        dataObject['suppliers'] = [$scope.queryString.supplier];
      }
      

      if (typeof $scope.query !== 'undefined' ) {
        $scope.search();
      } else if (!jQuery.isEmptyObject(dataObject)) {
        $scope.staticsearchterm = '';
        $scope.isLoading = true;
        $http.post('/sitecore/api/ssc/Maroon.Web/CatalogApi/1/searchVerticals', dataObject )
          .then(function (response) {
            $scope.searchResults = response.data;
            $scope.searchTags.classes = response.data.classes;
            $scope.searchTags.industries = response.data.industries;
            $scope.searchTags.suppliers = response.data.suppliers;
            
            //addQueryString();
            $scope.verticalList = filterProducts();
            $scope.isLoading = false;

            $scope.operatingCompanies = response.data.operatingCompanies;
            $('.pill-area').show();
            $('.results-area').show();

            var tempstring = response.data.queryString;
            if (tempstring != '') {
              tempstring = tempstring;
            }
            else {
              tempstring = $scope.querystringToPass;
            }
            $scope.operatingCompaniesQueryString = tempstring;
          });
      }
    }

    $scope.selectSubClass = function (subclass) {
        if (!_.find($scope.selectedSubClasses, { 'scName': subclass.scName })) {
            $scope.selectedSubClasses.push(subclass);
        }
        $scope.verticalList = filterProducts();
    };

    $scope.unSelectSubClass = function (subclass) {
        _.remove($scope.selectedSubClasses, { 'scName': subclass.scName });
        $scope.verticalList = filterProducts();
    };

    $scope.getFilterCount = function (type, extraTags) {
        var extra = {};
        extra[type] = extraTags;
        return filterProducts(extra).length;
    }

    $scope.$watchGroup(['selectedClasses', 'selectedIndustries', 'selectedSuppliers'], function (newVal, oldVal) {
        $scope.verticalList = filterProducts();
        //setSearchTagCounts();
    }, true);

    $scope.removeFilter = function (collection, item) {
        item.checked = false;
        collection = $filter('filter')(collection, { checked: true });
    };

    var setSearchTagCounts = function () {

        _.forEach($scope.searchTags.classes, function (value) {
            _.find($scope.searchTags.classes, { 'scName': value.scName }).count = $scope.getFilterCount('class', value);
        });
        
        _.forEach($scope.searchTags.industries, function (value) {
            _.find($scope.searchTags.industries, { 'scName': value.scName }).count = $scope.getFilterCount('industry', value);
        });

        _.forEach($scope.searchTags.suppliers, function (value) {
            _.find($scope.searchTags.suppliers, { 'scName': value.scName }).count = $scope.getFilterCount('supplier', value);
        });

    };

    var addQueryString = function () {
        var products = null;
        // Filter by query string
        if ($scope.queryString.class) {
            var queryClass = _.find($scope.searchTags.classes, { 'scName': $scope.queryString.class });
            if (queryClass) {
                queryClass.checked = true;
                products = filterProducts({
                    class: queryClass
                });
            }
        }
      if ($scope.queryString.industry) {
        var queryIndustry = _.find($scope.searchTags.industries, { 'scName': $scope.queryString.industry });
           
        if (queryIndustry) {
                queryIndustry.checked = true;
                products = filterProducts({
                    industry: queryIndustry
                });
            }
        }
        if ($scope.queryString.supplier) {
            var querySupplier = _.find($scope.searchTags.suppliers, { 'scName': $scope.queryString.supplier });
            if (querySupplier) {
                querySupplier.checked = true;
                products = filterProducts({
                    supplier: querySupplier
                });
            }
        }

        if (products != null)
        {
            $scope.searchTags.classes = _.filter($scope.searchTags.classes, function (c) {
                return (_.some(products, function (item) {
                    return item.class != null && item.class.scName == c.scName;
                }));
            });
            $scope.searchTags.industries = _.filter($scope.searchTags.industries, function (i) {
                return (_.some(products, function (item) {
                    return _.find(item.industries, function (i_industry) {
                        return (i_industry.scName == i.scName);
                    });
                }));
            });
            $scope.searchTags.suppliers = _.filter($scope.searchTags.suppliers, function (s) {
                return (_.some(products, function (item) {
                    return item.supplier != null && item.supplier.scName == s.scName;
                }));
            });
        }
    };

    var filterProducts = function (extraTags) {

        // Add extra tags to be included in filter (for counting)
        if (extraTags === undefined) {
            extraTags = {};
        }

        var verticalList = $scope.searchResults.products;

        var selectedClasses = angular.copy($scope.selectedClasses),
            selectedSubClasses = angular.copy($scope.selectedSubClasses),
            selectedIndustries = angular.copy($scope.selectedIndustries),
            selectedSuppliers = angular.copy($scope.selectedSuppliers);

        if (extraTags.class) {
            if (!_.find(selectedClasses, { 'scName': extraTags.class.scName })) {
                selectedClasses.push(extraTags.class);
            }
        }
        if (extraTags.industry) {
          if (!_.find(selectedIndustries, { 'scName': extraTags.industry.scName })) {
                selectedIndustries.push(extraTags.industry);
            }
        }
        if (extraTags.supplier) {
            if (!_.find(selectedSuppliers, { 'scName': extraTags.supplier.scName })) {
                selectedSuppliers.push(extraTags.supplier);
            }
        }

        // Filter products based on criteria

        if (selectedClasses.length) {
            verticalList = _.filter(verticalList, function (item) {
                if (!item.class) {
                    return;
                }
                return _.find(selectedClasses, { 'scName': item.class.scName });
            });
        }

        if (selectedSubClasses.length) {
            verticalList = _.filter(verticalList, function (item) {
                return _.find(selectedSubClasses, function (subclass) {
                    return _.find(item.subclasses, { 'scName': subclass.scName });
                });
            });
        }

       if (selectedIndustries.length) {
            verticalList = _.filter(verticalList, function (item) {
                return _.find(selectedIndustries, function (industry) {
                    return _.find(item.industries, { 'scName': industry.scName });
                });
            });
          
        }

        if (selectedSuppliers.length) {
            verticalList = _.filter(verticalList, function (item) {
                if (!item.supplier) {
                    return;
                }
                return _.find(selectedSuppliers, { 'scName': item.supplier.scName });
            });
        }
        return verticalList;

    };

}]);

// Loading Message
angular.module('app').directive('loadingIndicator', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var html = angular.element('<div class="loadingMessage" ng-show="isLoading"><span><img src="/frontend/dist/images/loading.gif" width="32" height="32" /> Loading...</span></div>');
            $compile(html)(scope);
            element.prepend(html);
        }
    };
}]);
angular.module('app').directive('paginate', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        scope: true,
        require: '?ngModel',
        link: function (scope, elem, attrs, model) {

            // Debug warnings...

            if (!model) {
                console.warn('Need to set ng-model="model.items" on element, where model.items is the array you are paginating', elem);
                return false;
            }

            

            // Public Methods

            scope.setCurrentPage = function (val) {
                if (val < 1 || val > scope.numPages) {
                    return;
                }
                scope.currentPage = val;
                refresh();
            };

            // Private Methods

            var refresh = function (reset) {
                scope.currentPage = (reset == true || !scope.currentPage) ? 1 : scope.currentPage;
                //scope.pageSize = numPerPage();
                scope.numPages = Math.ceil(model.$modelValue.length / scope.pageSize);

                if (scope.currentPage > scope.numPages) {
                    scope.currentPage = scope.numPages;
                }

                scope.startingItem = (scope.currentPage - 1) * scope.pageSize;
                scope.rangeStart = scope.startingItem + 1;
                scope.rangeEnd = (scope.startingItem + scope.pageSize > scope.totalItems) ? scope.totalItems : scope.startingItem + scope.pageSize;
                scope.totalItems = model.$modelValue.length;

            };

            scope.pageSize = parseInt(attrs.itemsPerPage, 10) || 10;

            // Watches

            scope.$watch(function () {
                return model.$modelValue;
            }, function (newVal, oldVal) {
                refresh(true);
            });

            scope.$watch('currentPage', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    scope.setCurrentPage(newVal);
                }
            }, true);

            scope.$watch('pageSize', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    refresh();
                }
            }, true);

        }
    };
}]);
angular.module('app').directive('maroonBtnHref', ['$window', function ($window) {
    var directive = {
        scope: {
            maroonBtnHref: '='
        },
        link: function (scope, element, attrs) {

            scope.onHandleClick = function () {
                if (scope.maroonBtnHref) { 
                    $window.open(scope.maroonBtnHref, '_blank');
                }
            };
            angular.element(element).on('click', scope.onHandleClick);
        }
    };

    return directive;
}]); 
angular.module('app').directive('sidebarFilters', ['$filter', function ($filter) {
    var directive = {
        templateUrl: 'sidebarFilters.html',
        scope: {
            list: '=',
            type: '@',
            title: '@',
            selected: '=',
            getFilterCount: '&'
        },
        link: function (scope, element, attrs) {

            scope.showList = true;
            scope.showExpandedList = false;

            scope.$watch('list', function (newVal, oldVal) {
                scope.selected = $filter('filter')(scope.list, { checked: true });
            }, true);
            
        }
    };

    return directive;
}]);
angular.module('app').filter('toUrl', function () {
    return function (value) {
        if (!value)
            return value;
        return value.replace(' ', '-').toLowerCase();
    }
})
$('#mobileMenu').click(function() {
  $('.mobileNavigation').slideToggle();
  return false;
})

$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  if ($(window).width() > 991) {
    if (scroll >= 500) {
      $('#searchBox').addClass('fix-search');
      $('.pageBody').addClass('topMargin');
      $('.heroBanner').addClass('bottomMargin');
    } else {
      $('#searchBox').removeClass('fix-search');
      $('.pageBody').removeClass('topMargin');
      $('.heroBanner').removeClass('bottomMargin');
    }
  }
});

$('#filterClass').click(function() {
  if ($(this).html() == '<i class="fa fa-minus"></i>')
  {
    $(this).html('<i class="fa fa-plus"></i>');
  }
  else {
    $(this).html('<i class="fa fa-minus"></i>');
  }
  $('#classFilters').toggle('slow', function(){

  })
  return false;
});
$('#filterSuppliers').click(function() {
  if ($(this).html() == '<i class="fa fa-minus"></i>')
  {
    $(this).html('<i class="fa fa-plus"></i>');
  }
  else {
    $(this).html('<i class="fa fa-minus"></i>');
  }
  $('#supplierFilters').toggle('slow', function(){

  })
  return false;
});
$('#moreSuppliers').click(function() {
  $('#moreSuppliersButton').toggle('slow', function() {

  })
  $('#additionalSuppliers').toggle('slow', function() {

  })
  return false;
});
$('#filter').click(function() {
  $('#filter').toggle('slow', function() {

  })
  $('#view').toggle('slow', function() {
$('#close').toggle('slow', function() {

})
  })
  return false;
});
$('#close').click(function() {
  $('#filter').toggle('slow', function() {

  })
  $('#view').toggle('slow', function() {
    $('#close').toggle('slow', function() {

    })
    })
  return false;
})
/*$('.dropdownSearch').click(function() {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
  }
  else {
    $(this).addClass('active');
  }
  return false;
})*/




  $('.productCard').click(function(event) {

    var link = $(this).attr('data-link');
    var newTab = ($(this).attr('data-new-tab')) === 'true' ? '_blank' : '_self';

    if (link) {
      window.open(link, newTab);
    }
  });

  $(document).ready(function () {
      var sitecore = document.getElementById('scWebEditRibbon');
      if (sitecore) {
          $('.platformNavigation').addClass('sitecoreHeaderMargin');
      }
      
  });
$(document).ready(function () {
    var cards = $('.productCard');
    for (var i = 0; i < cards.length; i += 2) {
        var pair = cards.slice(i, i + 2);
        if (pair.length > 1) {
            if (pair[0].offsetHeight > pair[1].offsetHeight) {
                pair[1].style.height = pair[0].offsetHeight + 'px';
            }
            else {
                pair[0].style.height = pair[1].offsetHeight + 'px';
            }
        }
    }
    var pressListingCards = $('.pressListing').find('.standardCard:visible');
    for (var i = 0; i < pressListingCards.length; i += 2) {
        var pair = pressListingCards.slice(i, i + 2);
        if (pair.length > 1) {
            if (pair[0].offsetHeight > pair[1].offsetHeight) {
                pair[1].style.height = pair[0].offsetHeight + 'px';
            }
            else {
                pair[0].style.height = pair[1].offsetHeight + 'px';
            }
        }
    }
    setTimeout(function () {

        var pressCards = $('#pressCards').find('.standardCard:visible');
        adjustPressCardsHeight(pressCards);

        var employees = $('.staffProfile');
        for (var i = 0; i < employees.length; i += 4) {
            var fours = employees.slice(i, i + 4);
            if (fours.length > 1) {
                var maxH = 0;
                for (var x = 0; x < fours.length; x++) {
                    maxH = (fours[x].offsetHeight > maxH) ? fours[x].offsetHeight : maxH;
                }
                for (var x = 0; x < fours.length; x++) {
                    fours[x].style.height = maxH + 'px';
                }
            }
        }
    }, 3000);
    var customerStoryCards = $('#customerStoryCards').find('.standardCard:visible');
    adjustPressCardsHeight(customerStoryCards);
})
var triplet = 0;
$('#morePress').click(function () {
    $('#extraPress').find('.standardCard')
      .filter(function (i) {
          console.log(i);
          return i >= triplet * 3 && i < (triplet + 1) * 3
      })
      .show();
    triplet++;
    var pressCards = $('#pressCards').find('.standardCard:visible');
    adjustPressCardsHeight(pressCards);
});
var adjustPressCardsHeight = function (pressCards) {
    for (var i = 0; i < pressCards.length; i += 3) {
        var group = pressCards.slice(i, i + 3);
        if (group.length > 2) {
            if (group[0].offsetHeight > group[1].offsetHeight) {
                group[1].style.height = group[0].offsetHeight + 'px';
                if (group[1].offsetHeight > group[2].offsetHeight) {
                    group[2].style.height = group[1].offsetHeight + 'px';
                }
                else {
                    group[1].style.height = group[2].offsetHeight + 'px';
                    group[0].style.height = group[2].offsetHeight + 'px';
                }
            }
            else {
                group[0].style.height = group[1].offsetHeight + 'px';
                if (group[1].offsetHeight > group[2].offsetHeight) {
                    group[2].style.height = group[1].offsetHeight + 'px';
                }
                else {
                    group[1].style.height = group[2].offsetHeight + 'px';
                    group[0].style.height = group[2].offsetHeight + 'px';
                }
            }
        }
        else {
            if (group[0].offsetHeight > group[1].offsetHeight) {
                group[1].style.height = group[0].offsetHeight + 'px';
            }
            else {
                group[0].style.height = group[1].offsetHeight + 'px';
            }
        }
    }
}
$('input').keyup(function () {
    if ($.trim(this.value).length > 2) $('.dropdownTypeAhead').show()
    else $('.dropdownTypeAhead').hide()
});
$(document).mouseup(function (e) {
    var container = $('.dropdownTypeAhead');

    if (!container.is(e.target) // if the target of the click isn't the container...
      && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        $('.dropdownTypeAhead').hide();
    }
});
$(document).keyup(function (e) {
    if (e.keyCode === 27) $('.dropdownTypeAhead').hide();
});
   var li = $('.pageBody').find('.searchResult');
   var n = li.length;
   var r = -1;
$(window).keydown(function (e) {
    if (li.length > 0){
        var k = e.which;
        if (r >= 0) {
            li[r].className = 'searchResult';
        }
        if (k == 38 || k == 40) {
            r = (k == 38 ? --r : ++r) < 0 ? n - 1 : r % n;
            li[r].className = 'searchResult selected';
        }
    }
});
$('#pressPageInput').keypress(function (e) {
    if (e.which == 13) {
        $(this).blur();
    }
});